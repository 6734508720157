import EventBus from '../../../utils/eventBus'
import stepMixin from '../../../mixins/stepMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import TextList from '../../textList/TextList.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import FormRadio from '../../form/formRadio/FormRadio.vue'
import Camera from '../../camera/Camera.vue'
import { errorMessage } from '../../../constants/alertMessages'
import { mapActions, mapGetters } from 'vuex'
import { toBase64 } from '../../../constants/file'
import { taskTypes } from '../../../constants/tasks'

export default {
    name: 'LicensePlate',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        },
        label: {
            type: String,
            default: 'Kennzeichen'
        }
    },

    mixins: [stepMixin, checkTaskMixin],

    components: {
        TextList,
        FormInput,
        FormRadio,
        Camera
    },

    data() {
        return {
            correctVehicle: null,
            correctVehicleValues: [
                {
                    label: 'Ja',
                    value: true
                },
                {
                    label: 'Nein',
                    value: false
                }
            ]
        }
    },

    created() {
        const redirectPlate = localStorage.getItem('redirectPlate')
        if (typeof redirectPlate === 'string') {
            this.licensePlate = redirectPlate.toUpperCase()
            this.resetCorrectVehicle()
            this.triggerSearch()
            this.resetPhoto()
        }
    },

    computed: {
        ...mapGetters({
            form: 'form/form',
            ocr: 'ocr/ocr',
            loggedInUser: 'user/loggedInUser'
        }),

        vehicleItems() {
            return [
                {
                    label: 'Hersteller',
                    value: this.form.vehicleByLicensePlate.manufacturer,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.manufacturer !==
                            this.form.vehicleByLicensePlate.manufacturer
                },
                {
                    label: 'Modell',
                    value: this.form.vehicleByLicensePlate.model,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.model !==
                            this.form.vehicleByLicensePlate.model
                },
                {
                    label: 'Farbe',
                    value: this.form.vehicleByLicensePlate.color,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.color !==
                            this.form.vehicleByLicensePlate.color
                },
                {
                    label: 'Kennzeichen',
                    value: this.form.vehicleByLicensePlate.license_plate,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.license_plate !==
                            this.form.vehicleByLicensePlate.license_plate
                },
                {
                    label: 'FIN',
                    class: 'twoCols',
                    value: this.form.vehicleByLicensePlate.vin,
                    error:
                        this.isDifferentVehicle &&
                        this.form.vehicleByVin.vin !==
                            this.form.vehicleByLicensePlate.vin
                }
            ]
        },

        vin() {
            return (this.form.vin?.vin) || null
        },

        licensePlate: {
            get() {
                return this.field.licensePlate
            },
            set(value) {
                this.field.licensePlate = value
            }
        },

        licensePlatePhoto() {
            return this.field.licensePlatePhoto
        },

        isDifferentVehicle() {
            let isDifferent = false

            /*
            obsolete for now, because VIN scan was unreliable

            if (
                this.form &&
                this.form.vehicleByVin &&
                this.form.vehicleByLicensePlate &&
                (this.form.vehicleByVin.manufacturer !==
                    this.form.vehicleByLicensePlate.manufacturer ||
                    this.form.vehicleByVin.model !==
                        this.form.vehicleByLicensePlate.model ||
                    this.form.vehicleByVin.color !==
                        this.form.vehicleByLicensePlate.color ||
                    this.form.vehicleByVin.vin !==
                        this.form.vehicleByLicensePlate.vin)
            ) {
                isDifferent = true
            }
            */

            return isDifferent
        },

        vehicleFound() {
            return (
                this.form?.vehicleByLicensePlate &&
                Object.keys(this.form.vehicleByLicensePlate).length > 0
            )
        }
    },

    methods: {
        ...mapActions({
            fetchVehicle: 'form/fetchVehicle',
            setForm: 'form/setForm',
            setVehicle: 'form/setVehicle',
            scanLicensePlate: 'ocr/scanLicensePlate',
            resetScanError: 'ocr/resetScanError'
        }),

        validate() {
            let isValid = true

            if (this.isEmpty(this.field.licensePlate)) {
                isValid = false
            }

            if (
                this.isEmpty(this.field.correctVehicle) ||
                this.field.correctVehicle === false
            ) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        },

        async search() {
            let response
            let type = null

            if(this.isCheckin) type = taskTypes.CHECKIN
            if(this.isCheckout) type = taskTypes.CHECKOUT
            if(this.isRecordMissingPartsTask) type = taskTypes.MISSING_PARTS_CHECKIN

            if (this.field.licensePlate) {
                response = await this.fetchVehicle({
                    params: {
                        license_plate: this.field.licensePlate,
                        type
                    },
                    token: this.loggedInUser?.token
                })

                // Handling errors
                if (response?.error) {
                    const statusCode = response.error

                    if (statusCode === 403) {
                        // Token has been expired or is invalid, redirect to logout
                        this.$router.push({
                            name: 'Logout',
                            params: { message: errorMessage.SESSION_EXPIRED }
                        })
                    }

                    if (statusCode === 500) {
                        // Error from API
                        EventBus.$emit('showDialog', {
                            dialog: {
                                name: 'error',
                                type: 'hint',
                                showContact: true,
                                errors: [
                                    'Es gab ein Problem bei der Kommunikation mit der Schnittstelle.'
                                ]
                            }
                        })
                    }
                }
            }
        },

        reload() {
            this.resetCorrectVehicle()
            this.triggerSearch()
        },

        resetCorrectVehicle() {
            this.setForm({
                key: 'correctVehicle',
                value: true,
                step: 'vin'
            })
            this.setForm({
                key: 'correctVehicle',
                value: null,
                step: 'licensePlate'
            })
        },

        resetPhoto() {
            this.resetScanError('licensePlate')
        }
    },

    watch: {
        'form.vehicleByLicensePlate.contractStateIsValid' : {
            handler(val){
                EventBus.$emit('hideDialog')

                if (val === false) {
                    EventBus.$emit('showDialog', {
                        dialog: {
                            name: 'error',
                            type: 'hint',
                            showContact: true,
                            errors: [
                                'Vertragsstatus ist nicht gültig, Bitte Fleetpool kontaktieren.'
                            ]
                        }
                    })
                    // Reset vehicle and customer
                    this.setVehicle()
                } else {
                    // Set vehicle and customer
                    this.setVehicle(this.form.vehicleByLicensePlate)
                }
            }
        },
        licensePlate() {
            this.field.licensePlate = this.field.licensePlate?.toUpperCase() ?? ''
            this.resetCorrectVehicle()
            this.triggerSearch()
            this.resetPhoto()
        },

        correctVehicle(val) {
            EventBus.$emit('hideDialog')

            if (val === false) {
                EventBus.$emit('showDialog', {
                    dialog: {
                        name: 'error',
                        type: 'hint',
                        showContact: true,
                        errors: [
                            'Anhand des eingegebenen Kennzeichens wurde nicht das korrekte Fahrzeug gefunden.'
                        ]
                    }
                })
                // Reset vehicle and customer
                this.setVehicle()
            } else {
                // Set vehicle and customer
                this.setVehicle(this.form.vehicleByLicensePlate)
            }
        },

        licensePlatePhoto(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                toBase64(newVal).then(imageBase64 => {
                    const token = this.loggedInUser?.token
                    this.scanLicensePlate(
                      {
                          image: imageBase64,
                          token
                      }
                    )
                })
            }
        }
    }
}
